import pathToRegexp, {PathFunction} from 'path-to-regexp';
import flag from 'country-code-emoji';
import qs from 'query-string';

export enum Locale {
  UK = 'uk',
  EN = 'en',
}

export const DEFAULT_LOCALE = Locale.EN;

export enum AlgorithmType {
  ImageClassifier = 'image_classifier',
  TextEntityExtractorOld = 'text_entity_extractor_old',
  TextEntityExtractor = 'text_entity_extractor',
  TextClassifier = 'text_classifier',
  ImageLocalizer = 'image_localizer',
  Grouper = 'grouper',
  ImageComparator = 'image_comparator',
  TabularClassifier = 'tabular_classifier',
}

export enum UserRole {
  NewUser = 'new_user',
  Root = 'root',
  Admin = 'admin',
  MlEngineer = 'ml_engineer',
  Moderator = 'moderator',
  None = 'none',
}

export const VERIFIED_USER_ROLES = [
  UserRole.Root,
  UserRole.Admin,
  UserRole.MlEngineer,
  UserRole.Moderator,
];

const ALGORITHM_TYPES_SUPPORTING_MULTIPLE_OPTIONS_SELECTION = [
  AlgorithmType.ImageClassifier,
  AlgorithmType.ImageComparator,
  AlgorithmType.TabularClassifier,
  AlgorithmType.TextClassifier,
];

export const isMultipleOptionsSelectionSupportedByAlgorithm = (
  algorithmType: AlgorithmType
) =>
  ALGORITHM_TYPES_SUPPORTING_MULTIPLE_OPTIONS_SELECTION.includes(algorithmType);

export enum LocalStorageKey {
  Locale = 'locale',
  Theme = 'theme',
}

export enum Theme {
  Dark = 'dark',
  Light = 'light',
}

export const DEFAULT_THEME = Theme.Dark;

export enum PageRoute {
  AlgorithmsList = '/algorithms',
  ModeratedTasks = '/algorithms/training-set',
  Admin = '/admin',
  AccessDenied = '/access-denied',
  SignIn = '/login',
  Index = '/',
  UsersList = '/admin/users',
  Map = '/map',
  TasksModerate = '/algorithms/:algorithmId/tasks/moderate',
  TasksList = '/algorithms/:algorithmId/tasks/list',
  Algorithm = '/algorithms/:algorithmId',
  AlgorithmSettings = '/algorithms/:algorithmId/edit',
  AlgorithmApi = '/algorithms/:algorithmId/api',
  AlgorithmModeratorsPerformance = '/algorithms/:algorithmId/moderators-performance',
  AlgorithmAdd = '/algorithms/add',
  TasksAdd = '/algorithms/:algorithmId/tasks/add',
  TasksSimpleAdd = '/algorithms/:algorithmId/tasks/add:isSimpleSuffix',
  TasksProcessings = '/algorithms/:algorithmId/tasks/processings',
  User = '/users/:userId',
  Messages = '/messages',
  Arbitrary = '/algorithms/:algorithmId/arbitraries/:arbitraryId',
  ArbitrariesList = '/algorithms/:algorithmId/arbitraries/list',
}

export const getNestedSubroutePathFor = (baseRoute: string, route: string) =>
  route.replace(baseRoute, '');

interface CreateAlgorithmPagePathProps {
  algorithmId: Number;
}

interface TasksAddPathProps {
  algorithmId: Number;
  isSimpleSuffix: String;
}

interface CreateUserPagePathProps {
  userId: Number;
}

interface CreateArbitrarySectionPathProps {
  algorithmId: Number;
  arbitraryId: String;
}

type CreateAlgorithmPagePathOptions = {
  sourceAlgorithmId?: number;
};

export const createAddAlgorithmPagePath = ({
  sourceAlgorithmId,
}: CreateAlgorithmPagePathOptions) => {
  return qs.stringifyUrl({
    url: PageRoute.AlgorithmAdd,
    query: {
      sourceAlgorithmId: sourceAlgorithmId ? String(sourceAlgorithmId) : null,
    },
  });
};

export const createAlgorithmSettingsPagePath: PathFunction<CreateAlgorithmPagePathProps> =
  pathToRegexp.compile(PageRoute.AlgorithmSettings);

export const createAlgorithmApiSectionPath: PathFunction<CreateAlgorithmPagePathProps> =
  pathToRegexp.compile(PageRoute.AlgorithmApi);

export const createAlgorithmModeratorsPerformanceSectionPath: PathFunction<CreateAlgorithmPagePathProps> =
  pathToRegexp.compile(PageRoute.AlgorithmModeratorsPerformance);

export const createAlgorithmPagePath: PathFunction<CreateAlgorithmPagePathProps> =
  pathToRegexp.compile(PageRoute.Algorithm);

export const createTasksAddPagePath: PathFunction<TasksAddPathProps> =
  pathToRegexp.compile(PageRoute.TasksAdd);

export const createTasksSimpleAddPagePath: PathFunction<TasksAddPathProps> =
  pathToRegexp.compile(PageRoute.TasksSimpleAdd);

export const createTasksProcessingsPagePath: PathFunction<CreateAlgorithmPagePathProps> =
  pathToRegexp.compile(PageRoute.TasksProcessings);

export const createTasksModeratePagePath: PathFunction<CreateAlgorithmPagePathProps> =
  pathToRegexp.compile(PageRoute.TasksModerate);

export const createTasksListSectionPath: PathFunction<CreateAlgorithmPagePathProps> =
  pathToRegexp.compile(PageRoute.TasksList);

export const createArbitrariesListSectionPath: PathFunction<CreateAlgorithmPagePathProps> =
  pathToRegexp.compile(PageRoute.ArbitrariesList);

export const createArbitrarySectionPath: PathFunction<CreateArbitrarySectionPathProps> =
  pathToRegexp.compile(PageRoute.Arbitrary);

export const createUserPagePath: PathFunction<CreateUserPagePathProps> =
  pathToRegexp.compile(PageRoute.User);

export interface IOption {
  id: string;
  title: string;
  color: string;
  answersCount: number;
}

export interface IAnswerItem {
  id: string;
  data?: any;
}

export type IAnswer = Array<IAnswerItem>;

export interface ITask {
  taskId: string;
  algorithmId: number;
  representativeData: any;
  arbitraryId: string | null;
  samples: ISample[];
  modelAnswer: IAnswer | null;
  tags: string[];
}

export interface DailyProductivityItem {
  dayTime: string;
  count: number;
  currentUserCount?: number;
}

export interface HourlyProductivityItem {
  hourTime: string;
  count: number;
}

export interface IAlgorithm {
  algorithmId: number;
  section: string;
  humanName: string;
  type: AlgorithmType;
  description: string;
  apiUrl: string;
  storage: string;
  options: IOption[];
  allowMultipleOptionsSelection: boolean;
  unfinishedTasksCount: number;
  moderatedTasksCount: number;
  answersDailyProductivity: DailyProductivityItem[];
  active: boolean;
  notifications: boolean;
  keywords: string[];
  arbitrationCreationCode: string;
  arbitrationCreationIntervalDays: number;
  nextArbitrationCreationDate: string | null;
  docsNotionPageId: string | null;
  hasDocs: boolean;
}

export const getFlag = (isoCode: string) => {
  try {
    return flag(isoCode);
  } catch (error) {
    return '⚙️';
  }
};

export const getAlgorithmTitle = (
  section: IAlgorithm['section'],
  humanName: IAlgorithm['humanName']
) => {
  return `${section} ${humanName}`;
};

export interface IArbitrary {
  algorithmId: IAlgorithm['algorithmId'];
  arbitraryId: string;
  humanName: string;
  comment: string;
  insertTime: string;
  tasksCount: number;
  finishedTasksCount: number;
  answersStatistics: {
    optionId: IOption['id'];
    correct: number;
    wrong: number;
    unknown: number;
  }[];
}

export interface IUser {
  identifier: string;
  userId: number;
  role: UserRole;
  messages: IMessage[];
  dailyProductivity: DailyProductivityItem[];
  hourlyProductivity: HourlyProductivityItem[];
  fullName: string;
  photoURL: string | null;
}

export interface IMessage {
  text: string;
  messageId: string;
  checked: boolean;
  time: string;
}

export interface ISample {
  answer: IAnswer;
  comment: string | null;
  user: {
    identifier: IUser['identifier'];
    userId: IUser['userId'];
  };
  insertTime: string;
  updateTime: string | null;
}

export interface IWord {
  value: string;
  index: number;
  optionId?: string | null;
}
